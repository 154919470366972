// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GoogleLoginPage from './GoogleLoginPage'; // Import the login page
import LoadingPage from './LoadingPage'; // Loading page for OAuth handling
import Chat from './Chat'; // Chat component for existing users
import ProfileSetup from './ProfileSetup'; // Component for new user setup

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GoogleLoginPage />} /> {/* Login page */}
        <Route path="/loading" element={<LoadingPage />} /> {/* Loading page for OAuth */}
        <Route path="/home" element={<Chat />} /> {/* Chat page */}
        <Route path="/profile-setup" element={<ProfileSetup />} /> {/* Profile setup page */}
      </Routes>
    </Router>
  );
}

export default App;
