import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LoadingPage.css';

const LoadingPage = () => {
  const navigate = useNavigate();

  // URL에서 인증 코드를 추출
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');

  const handleLoginPost = useCallback(async (code) => {
    if (process.env.REACT_APP_MODE === 'development' && window.location.protocol === 'http:') {
      window.location.replace(`${process.env.REACT_APP_FRONTEND_ENDPOINT}/loading` + window.location.search + window.location.hash);
      return;
    }

    if (!code) {
      console.error('인증 코드가 없습니다.');
      navigate('/'); // 인증 코드가 없을 경우 로그인 페이지로 리다이렉트
      return;
    }

    try {
      // 인증 코드를 백엔드로 전송하여 액세스 토큰 획득
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/auth/google`, {
        code,
        redirect_uri: (
          process.env.REACT_APP_MODE === 'development'
          ? `http://localhost:3000/loading`
          : `${process.env.REACT_APP_FRONTEND_ENDPOINT}/loading`
        ),
      }, {
        withCredentials: true,
      });

      const { accessToken, isExistingMember } = response.data;

      // 액세스 토큰을 로컬 스토리지에 저장
      localStorage.setItem('accessToken', accessToken);

      // 신규 회원인지 기존 회원인지에 따라 리다이렉트
      if (isExistingMember) {
        navigate('/profile-setup'); // 신규 회원은 프로필 설정 페이지로 리다이렉트
      } else {
        navigate('/home'); // 기존 회원은 채팅 화면으로 리다이렉트
      }
    } catch (error) {
      console.error('인증 중 오류가 발생했습니다:', error);
      navigate('/'); // 오류 발생 시 로그인 페이지로 리다이렉트
    }
  }, [navigate]);

  useEffect(() => {
    if (code) {
      handleLoginPost(code); // 인증 코드를 이용하여 로그인 처리
    } else {
      console.error('인증 코드가 없습니다.');
      navigate('/'); // 인증 코드가 없을 경우 로그인 페이지로 리다이렉트
    }
  }, [code, handleLoginPost, navigate]);

  return (
    <div className="loading-container">
      <div className="loading-animation">
        <div className="loading-spinner"></div>
        <p className="loading-text">로그인 중입니다. 잠시만 기다려 주세요...</p>
      </div>
    </div>
  );
};

export default LoadingPage;

