import React from 'react';
import './GoogleLoginPage.css';

const GoogleLoginPage = () => {
  const handleLogin = () => {
    const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID; // Google Client ID
    const redirectUri =
      process.env.REACT_APP_MODE === 'development'
        ? `http://localhost:3000/loading`
        : `${process.env.REACT_APP_FRONTEND_ENDPOINT}/loading`;

    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&access_type=offline&prompt=consent&redirect_uri=${redirectUri}&response_type=code&scope=openid email profile`;

    console.log('Redirecting to:', url);
    window.location.href = url;
  };

  return (
    <div className="google-login-page">
      <div className="google-login-card">
        <h1 className="google-login-title">Carbon에 오신 것을 환영합니다</h1>
        <p className="google-login-description">
          Google 계정으로 로그인하여 AI와 대화하고 스마트한 답변을 받아보세요.
        </p>
        <button className="google-login-button" onClick={handleLogin}>
          <div className="google-icon" />
          Google 계정으로 로그인
        </button>
      </div>
    </div>
  );
};

export default GoogleLoginPage;

