import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ProfileSetup.css';

const ProfileSetup = () => {
  const [nickname, setNickname] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const updateNickname = async () => {
    if (!nickname.trim()) {
      alert('닉네임을 입력해 주세요!');
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_ENDPOINT}/nickName/${nickname}`);
      if (response.data.error === 'DUPLICATE_NICKNAME') {
        alert('이미 사용 중인 닉네임입니다. 다른 닉네임을 선택해 주세요.');
        return;
      }
      setSuccessMessage('닉네임이 성공적으로 저장되었습니다!');
      setTimeout(() => {
        navigate('/home'); // Chat.js로 이동
      }, 1000);
    } catch (error) {
      console.error('닉네임 저장 중 오류가 발생했습니다:', error);
      alert('닉네임 저장에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  return (
    <div className="profile-setup-container">
      <div className="profile-setup-card">
        <h1 className="profile-setup-title">프로필 설정</h1>
        <p className="profile-setup-description">원하는 닉네임을 설정하세요.</p>
        <input
          type="text"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          placeholder="닉네임 입력"
          className="profile-setup-input"
        />
        <button onClick={updateNickname} className="profile-setup-button">
          저장
        </button>
        {successMessage && <p className="profile-setup-success">{successMessage}</p>}
      </div>
    </div>
  );
};

export default ProfileSetup;
