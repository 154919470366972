// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // React 18 uses 'react-dom/client'
import App from './App'; // Main App component
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import the Google OAuth provider
import './index.css'; // Global styles if any

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  );
} else {
  console.error("Root element not found. Make sure there's a div with id 'root' in your index.html.");
}
