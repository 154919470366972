import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Chat.css';

function Chat() {
  const [chatRooms, setChatRooms] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [newChatName, setNewChatName] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [nickname, setNickname] = useState(''); // 닉네임 상태 추가
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchChatRooms();
    fetchNickname(); // 닉네임 가져오기
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchChatRooms = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/chatList`, { withCredentials: true });
      const chatRooms = response.data.map((chat) => ({
        id: chat.uuid,
        name: chat.name,
        documentID: chat.documentID,
        conversationIDs: chat.conversationIDs,
      }));
      setChatRooms(chatRooms);
    } catch (error) {
      console.error('채팅방 목록을 가져오는 데 실패했습니다:', error);
    }
  };

  const fetchNickname = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/nickName`, {
        withCredentials: true,
      });
      setNickname(response.data.nickname);
    } catch (error) {
      console.error('닉네임을 가져오는 데 실패했습니다:', error);
    }
  };

  const handleNicknameReset = () => {
    navigate('/profile-setup'); // 닉네임 재설정 페이지로 이동
  };

  const selectChat = async (chat) => {
    if (!chat || !chat.id) {
      console.error('선택한 채팅방이 유효하지 않습니다:', chat);
      alert('올바른 채팅방을 선택하세요.');
      return;
    }
    setSelectedChat(chat);
    await fetchChatMessages(chat.id);
  };

  const fetchChatMessages = async (chatId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/conversationData`, {
        params: { chatId },
        withCredentials: true,
      });
      const conversationData = response.data || [];

      const formattedMessages = conversationData.flatMap(({ question, answer }) => [
        { sender: 'user', text: question },
        { sender: 'bot', text: answer },
      ]);
      setMessages(formattedMessages);
      scrollToBottom(); // 대화 내역을 불러온 후 스크롤 이동
    } catch (error) {
      console.error('이전 대화 내역을 가져오는 데 실패했습니다:', error);
      setMessages([]);
    }
  };

  const sendMessage = async () => {
    if (!messageInput.trim() && !selectedFile) return;

    if (!selectedChat || !selectedChat.id) {
      alert('메시지를 보내기 전에 채팅방을 선택하세요.');
      return;
    }

    if (selectedFile) {
      await uploadPDF();
    } else {
      const newMessage = {
        chatId: selectedChat.id,
        question: messageInput,
      };

      try {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_ENDPOINT}/conversation`, newMessage);
        setMessages((prev) => [
          ...prev,
          { sender: 'user', text: messageInput },
          { sender: 'bot', text: response.data.answer },
        ]);
        setMessageInput('');
        scrollToBottom(); // 메시지 전송 후 스크롤 이동
      } catch (error) {
        console.error('메시지를 보내는 데 실패했습니다:', error);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault();
        setMessageInput((prev) => prev + '\n');
      } else {
        event.preventDefault();
        sendMessage();
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const uploadPDF = async () => {
    if (!selectedChat || !selectedChat.id) {
      alert('PDF를 업로드하기 전에 채팅방을 선택하세요.');
      return;
    }

    const fileName = selectedFile.name;

    setUploadStatus('PDF 업로드 중...');

    try {
      const form = new FormData()
      form.append('fileName', fileName);
      form.append('fileData', selectedFile);

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/chat/${selectedChat.id}/document`,
        form,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      setMessages((prev) => [
        ...prev,
        { sender: 'user', text: `업로드됨: ${fileName}` },
        { sender: 'bot', text: response.data.message },
      ]);
      setSelectedFile(null);
      setUploadStatus('업로드 성공!');
      scrollToBottom(); // 파일 업로드 후 스크롤 이동
    } catch (error) {
      console.error('PDF 업로드 중 오류가 발생했습니다:', error);
      setMessages((prev) => [
        ...prev,
        { sender: 'user', text: `업로드 실패: ${fileName}` },
      ]);
      setSelectedFile(null);
      setUploadStatus('업로드 실패.');
    }
  };

  const deleteChatRoom = async (chatId) => {
    if (!chatId) {
      console.error('삭제하려는 채팅방 ID가 유효하지 않습니다.');
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/chat/${chatId}`, { withCredentials: true });
      setChatRooms((prev) => prev.filter((chat) => chat.id !== chatId));
      if (selectedChat && selectedChat.id === chatId) {
        setSelectedChat(null);
        setMessages([]);
      }
    } catch (error) {
      console.error('채팅방 삭제에 실패했습니다:', error);
    }
  };

  const createChatRoom = async () => {
    if (!newChatName.trim()) return;

    try {
      const formData = new FormData();
      formData.append('name', newChatName);

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/createChat`, formData, {
        withCredentials: true,
      });
      const newChat = { id: response.data, name: newChatName };
      setChatRooms((prev) => [...prev, newChat]);
      setNewChatName('');
    } catch (error) {
      console.error('채팅방 생성에 실패했습니다:', error);
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/logout`, {}, { withCredentials: true });
      window.location.href = '/';
    } catch (error) {
      console.error('로그아웃 중 오류가 발생했습니다:', error);
    }
  };

  const deleteAccount = async () => {
    if (window.confirm('계정을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.')) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/delete`, { withCredentials: true });
        window.location.href = '/';
      } catch (error) {
        console.error('계정 삭제 중 오류가 발생했습니다:', error);
      }
    }
  };

  return (
    <div className="chat-app">
      <div className="fixed-header">
        <h1>Carbon</h1>
        <div className="account-actions">
          <p className="nickname-display">현재 닉네임: {nickname}</p>
          <button onClick={handleNicknameReset}>닉네임 재설정</button>
          <button onClick={logout}>로그아웃</button>
          <button onClick={deleteAccount}>계정 삭제</button>
        </div>
      </div>
      <div className="content">
        <div className="sidebar">
          <div className="chat-rooms">
            {chatRooms.map((chat) => (
              <div
                key={chat.id}
                className={`chat-room ${selectedChat && selectedChat.id === chat.id ? 'selected' : ''}`}
                onClick={() => selectChat(chat)}
              >
                <p>{chat.name}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteChatRoom(chat.id);
                  }}
                  className="delete-room-btn"
                >
                  삭제
                </button>
              </div>
            ))}
          </div>
          <div className="create-chat">
            <input
              type="text"
              value={newChatName}
              onChange={(e) => setNewChatName(e.target.value)}
              placeholder="채팅방 이름 입력"
            />
            <button onClick={createChatRoom}>생성</button>
          </div>
        </div>
        <div className="chat-window">
          {selectedChat ? (
            <>
              <div className="chat-header">
                <h2>{selectedChat.name}</h2>
              </div>
              <div className="chat-messages">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`message-wrapper ${
                      msg.sender === 'user' ? 'user-message-wrapper' : 'bot-message-wrapper'
                    }`}
                  >
                    <div
                      className={`message ${
                        msg.sender === 'user' ? 'user-message' : 'bot-message'
                      }`}
                    >
                      <p>{msg.text}</p>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} /> {/* 스크롤 기준점 */}
              </div>
              <div className="message-input">
                <textarea
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  placeholder="메시지를 입력하세요 (Shift+Enter: 줄바꿈)"
                  rows="1"
                  onKeyDown={handleKeyDown}
                />
                <div className="input-actions">
                  <label htmlFor="file-upload" className="file-upload-btn">
                    📎
                    <input
                      id="file-upload"
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                  </label>
                  <button onClick={sendMessage} className="send-btn">
                    전송
                  </button>
                </div>
              </div>
              {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
            </>
          ) : (
            <div className="no-chat-selected">
              <p>채팅방을 선택하거나 새로 만드세요</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chat;
